import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import _ from 'lodash';

import scenarioActions from 'actions/scenarioActions';
import UIActions from 'actions/UIActions';

import Questions from 'lib/Questions';
import { arrayOfTruthyKeys } from 'shared/lib/utils';

import { REFINANCE_PROPERTY_PATH } from 'shared/constants/paths';
import { REQUIRED } from 'constants/validators';
import { DEFAULT_EXISTING_PROPERTY_ID } from 'shared/constants/defaults';
import { LENDER_OPTIONS } from 'constants/options';
import { getDefaultExistingProperty } from 'shared/lib/propertyHelper';

import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';
import sanityCheck from 'hocs/sanityCheck';

import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import Question from 'components/Question/Question';
import View from 'components/View/View';
import ButtonNext from 'components/ButtonNext/ButtonNext';
import InputWithSpan from 'components/InputWithSpan/InputWithSpan';
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';
import Textarea from 'components/Textarea/Textarea';
import Selection from 'components/Selection/Selection';

const questionsUnderHeadings = {
  renovate: [
    ['renovationCost', REQUIRED],
    ['renovationPurpose', REQUIRED],
  ],
  consolidateDebt: [['consolidateDebtAmount', REQUIRED]],
  betterDeal: [['currentLenderId', REQUIRED], 'currentInterestRate'],
  additionalFunds: [['additionalFunds', REQUIRED]],
};

const orderedHeadings = [
  'betterDeal',
  'renovate',
  'consolidateDebt',
  'additionalFunds',
];

const propsTransformForQuestion = (props) => ({
  refinanceReasons: props.scenario.refinanceReasons,
  ...getDefaultExistingProperty(props.scenario),
});

class RefinancingDetails extends Component {
  static propTypes = {
    scenario: PropTypes.object.isRequired,
    ...manageQuestionsPropTypes,
    setPropertyCurrentLenderId: PropTypes.func.isRequired,
    startAnimation: PropTypes.func.isRequired,
    setNextPath: PropTypes.func.isRequired,
    setPropertyRenovationCost: PropTypes.func.isRequired,
    setPropertyAdditionalFunds: PropTypes.func.isRequired,
    setPropertyRenovationPurpose: PropTypes.func.isRequired,
    setPropertyConsolidateDebtAmount: PropTypes.func.isRequired,
    setPropertyCurrentInterestRate: PropTypes.func.isRequired,
  };

  static questionsToAsk = (props) => {
    const refinanceReasons = arrayOfTruthyKeys(props.refinanceReasons);
    const orderedRefinanceReasons = _.intersection(
      orderedHeadings,
      refinanceReasons,
    );
    const questions = new Questions();

    orderedRefinanceReasons.forEach((reason) => {
      questions.addBranch(...questionsUnderHeadings[reason]);
    });
    questions.add('linkButton');

    return questions.arrayOfQuestions();
  };

  static revealMethod = 'steps';
  static revealOverBranch = true;

  static isRequiredStructure = (props) =>
    !!getDefaultExistingProperty(props.scenario);

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSubmit = () => {
    this.props.startAnimation(['/refinancing-apply']);
    this.props.setNextPath(REFINANCE_PROPERTY_PATH);
  };

  render() {
    const {
      scenario,
      questions,
      formCompleted,
      setCurrentQuestionTo,
      setPropertyCurrentLenderId,
      setPropertyRenovationCost,
      setPropertyAdditionalFunds,
      setPropertyRenovationPurpose,
      setPropertyConsolidateDebtAmount,
      setPropertyCurrentInterestRate,
    } = this.props;
    const property = getDefaultExistingProperty(scenario);

    return (
      <View>
        <ContentsWrapper
          id='refinanceDetails'
          title='Please tell us a bit more about what you’re looking to do.'
          isForm
          formCompleted={formCompleted}
          onSubmit={this.handleSubmit}
          onFocusLost={setCurrentQuestionTo(undefined)}
        >
          {questions.currentLenderId && questions.currentLenderId.visibility && (
            <h4 id='betterDealHeader' className='brandColor__font'>
              Getting a better deal
            </h4>
          )}
          <Question
            {...questions.currentLenderId}
            label='Who is your current lender?'
          >
            <Selection
              items={LENDER_OPTIONS()}
              action={setPropertyCurrentLenderId}
              value={property.currentLenderId}
            />
          </Question>
          <Question
            {...questions.currentInterestRate}
            label='What is your current interest rate?'
            hint='Don’t know your current interest rate? Just leave this blank for now one of our brokers can help you find out.'
          >
            <InputWithSpan
              action={setPropertyCurrentInterestRate}
              value={property.currentInterestRate}
              spanValue='%'
            />
          </Question>
          {questions.renovationCost && questions.renovationCost.visibility && (
            <h4 id='renovateHeader' className='brandColor__font'>
              Renovating your property
            </h4>
          )}
          <Question
            {...questions.renovationCost}
            label='How much do you need for renovations?'
          >
            <CurrencyInput
              action={setPropertyRenovationCost}
              value={property.renovationCost}
            />
          </Question>
          <Question
            {...questions.renovationPurpose}
            label='What renovations are you looking to do?'
          >
            <Textarea
              placeholder='e.g. Renovate my kitchen'
              action={setPropertyRenovationPurpose}
              value={property.renovationPurpose}
            />
          </Question>
          {questions.consolidateDebtAmount &&
            questions.consolidateDebtAmount.visibility && (
              <h4 id='consolidateDebtHeader' className='brandColor__font'>
                Consolidating your debt
              </h4>
            )}
          <Question
            {...questions.consolidateDebtAmount}
            label='Roughly, how much debt are you looking to consolidate?'
          >
            <CurrencyInput
              action={setPropertyConsolidateDebtAmount}
              value={property.consolidateDebtAmount}
            />
          </Question>
          {questions.additionalFunds && questions.additionalFunds.visibility && (
            <h4 id='additionalFundsHeader' className='brandColor__font'>
              Borrowing additional funds
            </h4>
          )}
          <Question
            {...questions.additionalFunds}
            label='How much additional you want to borrow?'
          >
            <CurrencyInput
              action={setPropertyAdditionalFunds}
              value={property.additionalFunds}
            />
          </Question>
          <ButtonNext
            id='next'
            {...questions.linkButton}
            disabled={!formCompleted}
            onClick={this.handleSubmit}
          />
        </ContentsWrapper>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  scenario: state.scenario,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPropertyCurrentLenderId: scenarioActions.setPropertyCurrentLenderId(
        DEFAULT_EXISTING_PROPERTY_ID,
      ),
      setPropertyRenovationCost: scenarioActions.setPropertyRenovationCost(
        DEFAULT_EXISTING_PROPERTY_ID,
      ),
      setPropertyAdditionalFunds: scenarioActions.setPropertyAdditionalFunds(
        DEFAULT_EXISTING_PROPERTY_ID,
      ),
      setPropertyRenovationPurpose: scenarioActions.setPropertyRenovationPurpose(
        DEFAULT_EXISTING_PROPERTY_ID,
      ),
      setPropertyConsolidateDebtAmount: scenarioActions.setPropertyConsolidateDebtAmount(
        DEFAULT_EXISTING_PROPERTY_ID,
      ),
      setPropertyCurrentInterestRate: scenarioActions.setPropertyCurrentInterestRate(
        DEFAULT_EXISTING_PROPERTY_ID,
      ),
      setNextPath: UIActions.setNextPath,
      startAnimation: UIActions.startAnimationSequence,
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  sanityCheck,
  manageQuestions,
)(RefinancingDetails, propsTransformForQuestion);
