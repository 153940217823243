/* eslint-disable sonarjs/cognitive-complexity */
import { push } from '@loan_market/react-router-redux-multi';
import locale from 'config/locale';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ApplyBrokerCard from 'components/ApplyBrokerCard/ApplyBrokerCard';
import ApplySummary from 'components/ApplySummary/ApplySummary';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import Spinner from 'components/Spinner/Spinner';
import View from 'components/View/View';

import UIActions from 'actions/UIActions';
import loanApplicationActions from 'actions/loanApplicationActions';
import otherActions from 'actions/otherActions';
import structureActions from 'actions/structureActions';

import * as UISelectors from 'selectors/UISelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as completionSelectors from 'selectors/completionSelectors';
import * as expenseSelectors from 'selectors/expenseSelectors';
import * as fundingSelectors from 'selectors/fundingSelectors';
import * as liabilitySelectors from 'selectors/liabilitySelectors';
import * as structureSelectors from 'selectors/structureSelectors';

import { structureToQueryString } from 'lib/compareHelper';
import LocalStorageProxy from 'lib/localStorageProxy';
import { formatProductName } from 'lib/utils/stringUtils';
import { DEFAULT_STRUCTURE_INITIAL_STATE } from 'shared/constants/defaults';

import {
  APPLICATION_PROGRESS_BASE_PATH,
  APPLY_BASE_PATH,
  BORROWING_CAPACITY_PATH,
  COMPARE_PAGE_PATH,
  INTEREST_SAVING_PATH,
  PRODUCT_DETAIL_PATH,
} from 'shared/constants/paths';
import { ReviewSectionsModal } from 'components/ReviewSectionsModal/ReviewSectionsModal';
import { useModal } from 'contexts/ModalContext';
import Modal from 'components/Modal/Modal';

export const messages = defineMessages({
  whereToNow: {
    id: 'Dashboard.whereToNow',
    defaultMessage: 'Where to now?',
  },
  myDashboard: {
    id: 'Dashboard.myDashboard',
    defaultMessage: 'My Dashboard',
  },
  completeProfile: {
    id: 'Dashboard.completeProfile',
    defaultMessage: 'Complete your profile',
  },
  viewProfile: {
    id: 'Dashboard.viewProfile',
    defaultMessage: 'View your profile',
  },
  completeProfileDescription: {
    id: 'Dashboard.completeProfileDescription',
    defaultMessage: '{percentageCompleted, number, percent} complete',
  },
  sendToBroker: {
    id: 'Dashboard.sendToBroker',
    defaultMessage: 'Send to your broker',
  },
  testPreapproval: {
    id: 'Dashboard.testPreapproval',
    defaultMessage: 'Test for pre-approval',
  },
  testPreapprovalDescription: {
    id: 'Dashboard.testPreapprovalDescription',
    defaultMessage: 'Understand your options',
  },
  borrowingCapacity: {
    id: 'Dashboard.borrowingCapacity',
    defaultMessage: 'View borrowing capacity',
  },
  borrowingCapacityDescription: {
    id: 'Dashboard.borrowingCapacityDescription',
    defaultMessage: 'See how much you can borrow',
  },
  borrowingCapacityDescriptionDisabled: {
    id: 'Dashboard.borrowingCapacityDescriptionDisabled',
    defaultMessage: 'To see how much you can borrow, complete your profile',
  },
  compareLoans: {
    id: 'Dashboard.compareLoans',
    defaultMessage: 'Compare home loans',
  },
  compareLoansDescription: {
    id: 'Dashboard.compareLoansDescription',
    defaultMessage: 'Find the right loan for you',
  },
  understandNext: {
    id: 'Dashboard.understandNext',
    defaultMessage: 'Understand what’s next',
  },
  understandNextDescription: {
    id: 'Dashboard.understandNextDescription',
    defaultMessage: 'The steps ahead',
  },
  reviewYourLoan: {
    id: 'Dashboard.reviewYourLoan',
    defaultMessage: 'Review your selected loan',
  },
  selectLoan: {
    id: 'Dashboard.selectLoan',
    defaultMessage: 'Select a loan',
  },
  shortlistLoan: {
    id: 'Dashboard.shortlistLoan',
    defaultMessage: 'Shortlist a loan',
  },
  interestSavings: {
    id: 'Dashboard.interestSavings',
    defaultMessage: 'View interest savings',
  },
  interestSavingsDescription: {
    id: 'Dashboard.interestSavingsDescription',
    defaultMessage: 'See how much you could save',
  },
  yourProfile: {
    id: 'Dashboard.yourProfile',
    defaultMessage: 'Your Profile',
  },
  loans: {
    id: 'Dashboard.loans',
    defaultMessage: 'Loans',
  },
  researchTools: {
    id: 'Dashboard.researchTools',
    defaultMessage: 'Research Tools',
  },
  nextSteps: {
    id: 'Dashboard.nextSteps',
    defaultMessage: 'Next Steps',
  },
});

export const Dashboard = (props) => {
  const { readLoanApplication, unsavedSlugs } = props;
  const { isModalShown, hideModal, modalConfig, showModal } = useModal();

  useEffect(() => {
    if (LocalStorageProxy.token) {
      readLoanApplication();
    }

    window.scrollTo(0, 0);
  }, [readLoanApplication]);

  const openPath = (path) => () => {
    props.push(path);
  };

  const handleSendForReviewConfirm = () => {
    const {
      startAnimationSequence,
      setApplicationTested,
      sendLoanApplicationForReview,
    } = props;

    sendLoanApplicationForReview();
    setApplicationTested(true);
    startAnimationSequence([
      // eslint-disable-next-line sonarjs/no-duplicate-string
      '/send-for-review',
      '/will-find-options',
      '/expect-a-call',
    ]);
    hideModal();
  };

  const handleSendForReviewClick = () => {
    if (!unsavedSlugs || unsavedSlugs.length === 0) {
      handleSendForReviewConfirm();
      return;
    }

    const adviserOrBroker = locale.isAU ? 'broker' : 'adviser';
    showModal({
      title: `Send to ${adviserOrBroker}?`,
      content: (
        <ReviewSectionsModal
          onReviewSectionsClick={goToApply}
          onSendToBrokerClick={handleSendForReviewConfirm}
          unreviewedSections={unsavedSlugs}
        />
      ),
    });
  };

  const startBorrowingCapacity = () => {
    const {
      startAnimationSequence,
      setNextPath,
      requestMaxBorrow,
      applicationId: loanAppId,
      contactId,
    } = props;

    startAnimationSequence(['/max-borrow-grid']);
    requestMaxBorrow({ isGoalSetter: false, contactId, loanAppId });
    setNextPath(BORROWING_CAPACITY_PATH);
  };

  const startInterestSaving = () => {
    const { startAnimationSequence, setNextPath } = props;

    startAnimationSequence(['/searching-grid-savings']);
    setNextPath(INTEREST_SAVING_PATH);
  };

  const goToApply = () => {
    const { applicationId, startAnimationSequence, isHandhold } = props;

    if (isHandhold) {
      startAnimationSequence(['/apply-handhold']);
    } else {
      openPath(`${APPLY_BASE_PATH}/${applicationId}`)();
    }
    hideModal();
  };

  const goToCompare = () => {
    const { startAnimationSequence, setNextPath, loanAmount } = props;
    const structure = {
      ...DEFAULT_STRUCTURE_INITIAL_STATE,
      loanAmount: loanAmount || DEFAULT_STRUCTURE_INITIAL_STATE.loanAmount,
    };
    setNextPath(`${COMPARE_PAGE_PATH}/?${structureToQueryString(structure)}`);
    startAnimationSequence(['/searching-grid-compare']);
  };

  const getTitle = () => {
    const {
      intl: { formatMessage },
      isHandhold,
    } = props;
    return formatMessage(
      isHandhold ? messages.whereToNow : messages.myDashboard,
    );
  };

  const gotToLoanDetails = () => {
    const {
      primaryStructure: { id: structureId, productId },
      loadStructure,
    } = props;
    const destinationPath = `${PRODUCT_DETAIL_PATH}/${productId}?source=dashboard`;

    loadStructure(structureId);
    openPath(destinationPath)();
  };

  const getLoanTileProps = () => {
    const {
      intl: { formatMessage },
      primaryStructure,
    } = props;

    return primaryStructure
      ? {
          title: formatMessage(messages.reviewYourLoan),
          titleDescription: formatProductName(
            primaryStructure.productDetail.name,
          ),
          image: primaryStructure.lenderInfo.logoUrl,
          imageAlt: primaryStructure.lenderInfo.lenderName,
          onClick: gotToLoanDetails,
        }
      : {
          title: formatMessage(messages.selectLoan),
          titleDescription: formatMessage(messages.shortlistLoan),
          iconName: 'sl-custom-magnifier',
          onClick: openPath(COMPARE_PAGE_PATH),
        };
  };

  const getProfileTitle = () => {
    const {
      intl: { formatMessage },
      percentageCompleted,
    } = props;

    return formatMessage(
      percentageCompleted >= 1
        ? messages.viewProfile
        : messages.completeProfile,
    );
  };

  const getPreApprovalTitle = () => {
    const {
      intl: { formatMessage },
      isSharedApplication,
    } = props;
    return formatMessage(
      isSharedApplication ? messages.sendToBroker : messages.testPreapproval,
    );
  };

  const getBorrowingCapacityDescription = () => {
    const {
      intl: { formatMessage },
      canViewMaxBorrow,
    } = props;
    return formatMessage(
      canViewMaxBorrow
        ? messages.borrowingCapacityDescription
        : messages.borrowingCapacityDescriptionDisabled,
    );
  };

  const renderTiles = () => {
    const {
      intl: { formatMessage },
      percentageCompleted,
      applicationId,
      isSharedApplication,
      canViewMaxBorrow,
    } = props;

    return (
      <>
        <div className='nestedRoundCorner'>
          <h5>{formatMessage(messages.yourProfile)}</h5>
          <ApplySummary
            key='completeProfile'
            id='completeProfile'
            title={getProfileTitle()}
            titleDescription={formatMessage(
              messages.completeProfileDescription,
              { percentageCompleted },
            )}
            iconName='sl-custom-id-5'
            onClick={goToApply}
            theme='standalone'
            noStatus
          />
        </div>
        {!isSharedApplication && locale.isAU && (
          <div className='nestedRoundCorner'>
            <h5>{formatMessage(messages.loans)}</h5>
            {locale.isAU && (
              <ApplySummary
                key='compare'
                id='compare'
                title={formatMessage(messages.compareLoans)}
                titleDescription={formatMessage(
                  messages.compareLoansDescription,
                )}
                iconName='sl-custom-percent-2'
                onClick={goToCompare}
                theme='standalone'
                noStatus
              />
            )}
            <ApplySummary
              key='reviewLoan'
              id='reviewLoan'
              {...getLoanTileProps()}
              theme='standalone'
              noStatus
            />
          </div>
        )}
        {!isSharedApplication && locale.isAU && (
          <div className='nestedRoundCorner'>
            <h5>{formatMessage(messages.researchTools)}</h5>
            <ApplySummary
              key='borrowingCapacity'
              id='borrowingCapacity'
              title={formatMessage(messages.borrowingCapacity)}
              titleDescription={getBorrowingCapacityDescription()}
              iconName='sl-custom-dollar-bag'
              onClick={startBorrowingCapacity}
              disabled={!canViewMaxBorrow}
              theme='standalone'
              noStatus
            />
            <ApplySummary
              key='interestSavings'
              id='interestSavings'
              title={formatMessage(messages.interestSavings)}
              titleDescription={formatMessage(
                messages.interestSavingsDescription,
              )}
              iconName='sl-custom-hand-coin'
              onClick={startInterestSaving}
              theme='standalone'
              noStatus
            />
          </div>
        )}
        <div className='nestedRoundCorner'>
          <h5>{formatMessage(messages.nextSteps)}</h5>
          <ApplySummary
            key='testPreapproval'
            id={isSharedApplication ? 'sendToBroker' : 'testPreapproval'}
            title={getPreApprovalTitle()}
            titleDescription={formatMessage(
              messages.testPreapprovalDescription,
            )}
            iconName='sl-custom-plane-paper-1'
            onClick={handleSendForReviewClick}
            theme='standalone'
            noStatus
          />
          <ApplySummary
            key='understandNext'
            id='understandNext'
            title={formatMessage(messages.understandNext)}
            titleDescription={formatMessage(messages.understandNextDescription)}
            iconName='sl-custom-rocket'
            onClick={openPath(
              `${APPLICATION_PROGRESS_BASE_PATH}/${applicationId}`,
            )}
            theme='standalone'
            noStatus
          />
        </div>
      </>
    );
  };

  const {
    isSpinnerLoading,
    advisor,
    advisorOrg,
    displayDataCollectionBanners,
  } = props;

  const applyBrokerCardProps = { advisor, advisorOrg };

  return (
    <>
      {isModalShown && <Modal {...modalConfig} hideModal={hideModal} />}
      <View inverseHeader>
        <meta name='robots' content='noindex, nofollow' />
        <Spinner loading={isSpinnerLoading}>
          <ContentsWrapper
            id='dashboard'
            title={getTitle()}
            inverseHeader
            successMessage={
              displayDataCollectionBanners
                ? 'Data collection successful! Using your data supplied, some sections have been pre-populated. Please review and add any missing information before submitting to your broker.'
                : null
            }
          >
            {renderTiles()}
            {!props.isCustomerCare && (
              <ApplyBrokerCard {...applyBrokerCardProps} />
            )}
          </ContentsWrapper>
        </Spinner>
      </View>
    </>
  );
};

Dashboard.propTypes = {
  isSpinnerLoading: PropTypes.bool,
  canViewMaxBorrow: PropTypes.bool,
  percentageCompleted: PropTypes.number,
  applicationId: PropTypes.number,
  isHandhold: PropTypes.bool.isRequired,
  isSharedApplication: PropTypes.bool.isRequired,
  primaryStructure: PropTypes.object,
  readLoanApplication: PropTypes.func.isRequired,
  setApplicationTested: PropTypes.func.isRequired,
  setNextPath: PropTypes.func.isRequired,
  requestMaxBorrow: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  startAnimationSequence: PropTypes.func.isRequired,
  sendLoanApplicationForReview: PropTypes.func.isRequired,
  loadStructure: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  loanAmount: PropTypes.number,
  isCustomerCare: PropTypes.bool,
  advisor: PropTypes.object,
  advisorOrg: PropTypes.object,
  contactId: PropTypes.number,
  unsavedSlugs: PropTypes.arrayOf(PropTypes.string),
};

Dashboard.defaultProps = {
  percentageCompleted: 0,
  canViewMaxBorrow: false,
  advisor: {},
  advisorOrg: {},
};

const mapStateToProps = (state) => ({
  isSpinnerLoading: UISelectors.hasActiveSpinners(state),
  percentageCompleted: completionSelectors.percentageCompleted(state),
  applicationId: applicationSelectors.getApplicationId(state),
  isHandhold: UISelectors.isHandholdOn(state),
  isSharedApplication: applicationSelectors.isSharedApplication(state),
  canViewMaxBorrow: completionSelectors.completedEnoughToViewMaxBorrow(state),
  primaryStructure: structureSelectors.primaryStructure(state),
  loanAmount: fundingSelectors.totalLoanRequired(state),
  isCustomerCare: applicationSelectors.isBrokerCustomerCare(state),
  contactId: clientSelectors.primaryApplicantContactId(state),
  advisor: state.advisor,
  advisorOrg: state.advisorOrg,
  displayDataCollectionBanners:
    expenseSelectors.displayDataCollectionBanners(state) &&
    liabilitySelectors.displayDataCollectionBanners(state),
  unsavedSlugs: applicationSelectors.unsavedSlugs(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      readLoanApplication: loanApplicationActions.readLoanApplication,
      startAnimationSequence: UIActions.startAnimationSequence,
      setApplicationTested: UIActions.setApplicationTested,
      setNextPath: UIActions.setNextPath,
      sendLoanApplicationForReview:
        loanApplicationActions.sendLoanApplicationForReview,
      loadStructure: structureActions.loadStructure,
      requestMaxBorrow: otherActions.requestMaxBorrow,
    },
    dispatch,
  );

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard),
);
