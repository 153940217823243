import {
  DATA_COLLECTION_PROCESS,
  DATA_COLLECTION_PROCESS_STATUS,
} from 'constants/dataCollection';
import { createSelector } from 'reselect';

export const dataCollectionState = (state) => state.dataCollection;

export const dataCollectionStatusAndEvents = createSelector(
  dataCollectionState,
  (state) => state.statusAndEvents,
);

export const isDataCollectionNotEnable = createSelector(
  dataCollectionStatusAndEvents,
  (status) =>
    status.collectionStatus === DATA_COLLECTION_PROCESS_STATUS.NotEnabled,
);

export const isDataCollectionPending = createSelector(
  dataCollectionStatusAndEvents,
  (status) =>
    status.collectionStatus === DATA_COLLECTION_PROCESS_STATUS.Pending ||
    status.collectionStatus === DATA_COLLECTION_PROCESS_STATUS.Begun,
);

export const hasDataCollectionFailed = createSelector(
  dataCollectionStatusAndEvents,
  (status) =>
    status.collectionStatus === DATA_COLLECTION_PROCESS_STATUS.Errored,
);

export const isDataCollectionSuccess = createSelector(
  dataCollectionStatusAndEvents,
  (status) =>
    status.collectionStatus === DATA_COLLECTION_PROCESS_STATUS.Completed,
);

export const isIdVerificationPending = createSelector(
  dataCollectionStatusAndEvents,
  (status) => {
    let isScantekPending = false;
    let isManualIdPending = false;

    const scanTekProcess = status?.subStatuses?.find(
      (item) => item.process === DATA_COLLECTION_PROCESS.Scantek,
    );
    const manualIdProcess = status?.subStatuses?.find(
      (item) => item.process === DATA_COLLECTION_PROCESS.ManuelIdVerify,
    );

    if (scanTekProcess) {
      isScantekPending =
        scanTekProcess.processStatus ===
          DATA_COLLECTION_PROCESS_STATUS.Pending ||
        scanTekProcess.processStatus === DATA_COLLECTION_PROCESS_STATUS.Begun;
    }
    if (manualIdProcess) {
      isManualIdPending =
        manualIdProcess.processStatus === DATA_COLLECTION_PROCESS_STATUS.Begun;
    }

    return isScantekPending || isManualIdPending;
  },
);

export const creditCheckProcess = createSelector(
  dataCollectionStatusAndEvents,
  (status) =>
    status?.subStatuses?.find(
      (item) => item.process === DATA_COLLECTION_PROCESS.Equifax,
    ),
);

export const isCreditCheckPending = createSelector(
  creditCheckProcess,
  (process) => {
    return (
      process?.processStatus === DATA_COLLECTION_PROCESS_STATUS.Pending ||
      process?.processStatus === DATA_COLLECTION_PROCESS_STATUS.Begun
    );
  },
);

export const isCreditCheckSuccessful = createSelector(
  creditCheckProcess,
  (process) => {
    return process?.processStatus === DATA_COLLECTION_PROCESS_STATUS.Completed;
  },
);

export const hasCreditCheckFailed = createSelector(
  creditCheckProcess,
  (process) => {
    if (process) {
      return process.processStatus === DATA_COLLECTION_PROCESS_STATUS.Errored;
    }
    return false;
  },
);

export const bankStatementProcess = createSelector(
  dataCollectionStatusAndEvents,
  (status) =>
    status?.subStatuses?.find(
      (item) => item.process === DATA_COLLECTION_PROCESS.Illion,
    ),
);

export const isBankStatementsPending = createSelector(
  bankStatementProcess,
  // eslint-disable-next-line sonarjs/no-identical-functions
  (process) => {
    return (
      process?.processStatus === DATA_COLLECTION_PROCESS_STATUS.Pending ||
      process?.processStatus === DATA_COLLECTION_PROCESS_STATUS.Begun
    );
  },
);

export const isBankStatementsSuccessful = createSelector(
  bankStatementProcess,
  (bankProcess) => {
    return (
      bankProcess?.processStatus === DATA_COLLECTION_PROCESS_STATUS.Completed
    );
  },
);

export const isBankStatementsSkipped = createSelector(
  bankStatementProcess,
  (bankProcess) => {
    return (
      bankProcess?.processStatus === DATA_COLLECTION_PROCESS_STATUS.Skipped
    );
  },
);

export const latestDataCollectionEventAt = createSelector(
  dataCollectionStatusAndEvents,
  (status) => {
    if (status.lastEventTime === null || status.lastEventTime === undefined) {
      return undefined;
    }
    return new Date(status.lastEventTime);
  },
);

export const selectedProcesses = createSelector(
  dataCollectionStatusAndEvents,
  (statusAndEvents) =>
    [
      DATA_COLLECTION_PROCESS.Scantek,
      DATA_COLLECTION_PROCESS.Illion,
    ].filter((process) =>
      statusAndEvents?.subStatuses?.some(
        (status) => status.process === process,
      ),
    ),
);

export const verificationExchange = createSelector(
  dataCollectionState,
  (state) => state.verificationExchange,
);

export const subStatuses = createSelector(
  dataCollectionStatusAndEvents,
  (statusEvents) => statusEvents.subStatuses,
);

export const isDataCollectionEnabled = createSelector(
  selectedProcesses,
  (selectedProcesses) => selectedProcesses.length > 0,
);
