import intlPolyfillWrapper from 'lib/intlPolyfill';

import React from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import {
  createHashHistory as createHistory,
  createMemoryHistory,
} from 'history';
import { Security } from '@okta/okta-react';

import {
  ConnectedRouter,
  routerMiddleware,
} from '@loan_market/react-router-redux-multi';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import routes from 'routes/routes';
import memoryRoutes, { HISTORY_NAMESPACE } from 'routes/memoryRoutes';
import createStoreWithMiddleware from 'store/store';
import { setupBrandColorStylesheet } from 'lib/brandColorHelpers';
import PageWrapper from 'containers/PageWrapper';
import { addLocationQuery, setupGlobalConfigs } from 'lib/setupIndex';

import { setupAbTests } from 'lib/abTestHelper';
import prepareStore, {
  prepareSession,
  subscribeOktaAuthState,
} from 'lib/prepareStore';
import setupRox from 'lib/rollout';
import initAmplitude from 'lib/amplitude';
import { getOktaAuth } from 'lib/okta';
import { initLogger as setupCoreLogger } from 'lib/coreLogger';

import locale from 'config/locale';
import messages from 'lang';
import formats from 'config/intlFormats';
import { getLendersList } from 'lib/lenders';
import lenders from 'shared/lib/lenderHelper';
import {
  COUNTRY_NEW_ZEALAND,
  COUNTRY_AUSTRALIA,
} from 'shared/constants/myCRMTypes/countries';
import {
  NZ_SLUG_REGEX,
  OKTA_CALLBACK_SLUG_REGEX,
} from 'shared/config/pathMatcher';

import Spinner from 'components/Spinner/Spinner';

import './index.css';

const oktaAuth = getOktaAuth();

const renderApp = async () => {
  render(
    <Spinner loading variant='default' />,
    document.querySelector('#root'),
  );

  setupGlobalConfigs();
  if (window.LM_CONFIG.AMPLITUDE_API_KEY) {
    initAmplitude();
  }

  if (window.LM_CONFIG.ROLLOUT_API_KEY) {
    await setupRox();
  }

  if (window.location.pathname.match(OKTA_CALLBACK_SLUG_REGEX)) {
    window.location.replace(
      `/#${window.location.pathname}${window.location.search}`,
    );
  }

  setupCoreLogger();

  prepareSession();
  setupAbTests();
  setupBrandColorStylesheet();

  const memoryHistory = createMemoryHistory();
  const history = createHistory();

  // TODO: should also set aggregatorId here or when available
  if (window.location.pathname.match(NZ_SLUG_REGEX)) {
    locale.countryCode = 'NZ';
    lenders.data = { _all: await getLendersList(COUNTRY_NEW_ZEALAND.id) };
  } else {
    locale.countryCode = 'AU';
    lenders.data = { _all: await getLendersList(COUNTRY_AUSTRALIA.id) };
  }

  history.listen(() => {
    addLocationQuery(history);
  });

  const historyMiddleware = [
    routerMiddleware(history),
    routerMiddleware(memoryHistory, HISTORY_NAMESPACE),
  ];

  const store = createStoreWithMiddleware(historyMiddleware);

  subscribeOktaAuthState(oktaAuth, store);
  prepareStore(store);

  /* force adding query when refreshing */
  addLocationQuery(history);
  addLocaleData(locale.data);

  const reactElement = document.querySelector('#root');

  intlPolyfillWrapper(() => {
    // needed for google optimise
    if (
      window.LM_CONFIG.TAG_MANAGER_ID &&
      window.asyncHide &&
      document &&
      document.documentElement
    ) {
      document.documentElement.className += ' async-hide';
      setTimeout(
        () => {
          document.documentElement.className = document.documentElement.className.replace(
            /\s*async-hide/g,
            '',
          );
        },
        window.LM_CONFIG.TAG_MANAGER_PREVIEW_ID ? 1000 : 4000,
      );
    }

    render(
      <Provider store={store}>
        <IntlProvider
          defaultLocale='en-AU'
          defaultFomats={formats['en-AU']}
          locale={locale.data.localeCode}
          messages={messages[locale.data.localeCode]}
          formats={formats[locale.data.localeCode]}
        >
          <div>
            <ConnectedRouter history={history}>
              <Security oktaAuth={oktaAuth}>
                <PageWrapper>{routes(store)}</PageWrapper>
              </Security>
            </ConnectedRouter>
            <ConnectedRouter
              history={memoryHistory}
              namespace={HISTORY_NAMESPACE}
            >
              <Security oktaAuth={oktaAuth}>{memoryRoutes(store)}</Security>
            </ConnectedRouter>
          </div>
        </IntlProvider>
      </Provider>,
      reactElement,
    );
  });
};

renderApp();
