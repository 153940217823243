/* eslint-disable react/no-unescaped-entities */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withOktaAuth } from '@okta/okta-react';

import UIActions from 'actions/UIActions';
import scenarioActions from 'actions/scenarioActions';

import * as UISelectors from 'selectors/UISelectors';

import sanityCheck from 'hocs/sanityCheck';

import MyCRMLogin from 'components/MyCRMLogin/MyCRMLogin';
import Spinner from 'components/Spinner/Spinner';

import View from 'components/View/View';
import Button from 'components/Button/Button';

import LocalStorageProxy from 'lib/localStorageProxy';
import { logger } from 'lib/coreLogger';
import * as scenarioSelectors from 'selectors/scenarioSelectors';
import FullBgContentsWrapper from 'components/FullBgContentsWrapper/FullBgContentsWrapper';

class SharedLogin extends Component {
  static propTypes = {
    isSpinnerLoading: PropTypes.bool.isRequired,
    sections: PropTypes.arrayOf(PropTypes.string),
    setPartialProfileSections: PropTypes.func.isRequired,
    requestVerifyRecoveryToken: PropTypes.func.isRequired,
    recoveryToken: PropTypes.string,
    setSharedClientId: PropTypes.func.isRequired,
    setSharedLoanAppId: PropTypes.func.isRequired,
    clientId: PropTypes.string,
    loanApplicationId: PropTypes.string,
    requestActivateFactFind: PropTypes.func,
    fullPageSpinnerLoading: PropTypes.bool,
    oktaAuth: PropTypes.object,
    trackFactFindSignupAttempt: PropTypes.func,
    trackFactFindSignupSuccess: PropTypes.func,
    recoveryTokenStatus: PropTypes.bool,
    setRecoveryTokenStatus: PropTypes.func,
  };

  static isRequiredStructure = (props) => !!props.recoveryToken;

  logger;

  constructor(props) {
    super(props);
    this.state = {
      showPasswordScreen: false,
    };
    this.logger = logger('SharedLogin');
  }

  componentDidMount() {
    const { clientId, recoveryToken, loanApplicationId } = this.props;
    this.logger.info({
      action: 'on shared login page',
      data: { recoveryToken, clientId, loanApplicationId },
    });
    if (recoveryToken && clientId) {
      this.handleRecoveryToken();
    } else {
      this.logger.info({
        action: 'navigate to non-handhold dashboard',
      });
    }
    this.setSections();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.setState({ showPasswordScreen: false });
    this.props.setRecoveryTokenStatus(false);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.recoveryTokenStatus) {
      this.setState({ showPasswordScreen: true });
    }
  }

  handleRecoveryToken() {
    const {
      clientId,
      loanApplicationId,
      setSharedClientId,
      setSharedLoanAppId,
      recoveryToken,
      requestVerifyRecoveryToken,
      trackFactFindSignupAttempt,
    } = this.props;
    this.logger.info({
      action: 'handle recovery token',
      data: { recoveryToken },
    });
    const sharedClientId = parseInt(clientId, 10);
    setSharedClientId(sharedClientId);
    LocalStorageProxy.selectedClientId = sharedClientId;
    setSharedLoanAppId(parseInt(loanApplicationId, 10));
    this.logger.info({ action: 'verify recovery token' });
    requestVerifyRecoveryToken(recoveryToken);
    trackFactFindSignupAttempt({ recoveryToken, clientId, loanApplicationId });
  }

  setSections() {
    const { sections = null, setPartialProfileSections } = this.props;
    this.logger.info({
      action: 'set sections',
      data: { sections },
    });
    LocalStorageProxy.partialProfileSections = JSON.stringify(sections);
    setPartialProfileSections(sections);
  }

  handleSubmit = () => {
    const { requestVerifyRecoveryToken, recoveryToken } = this.props;
    this.logger.info({
      action: 'verify recovery token',
      data: { recoveryToken },
    });
    requestVerifyRecoveryToken(recoveryToken);
  };

  renderHint = () => (
    <div className='small-font'>
      <i className='sl-custom-lock-2' />
      Log in securely. Please provide a password to secure your account.
    </div>
  );

  renderTokenValid = () => (
    <>
      <ul>
        <li>
          <i className='sl-custom-hand-like-1' />
          <span>Easy</span>
        </li>
        <li>
          <i className='sl-custom-clock-3' />
          <span>Takes 10-15 minutes</span>
        </li>
        <li>
          <i className='sl-custom-sync-2' />
          <span>Start now, finish later</span>
        </li>
      </ul>
      <Button
        id='next'
        onClick={this.handleSubmit}
        theme='big'
        className='brandColor__button'
      >
        Let’s get moving
      </Button>
      {this.renderHint()}
    </>
  );

  handlePasswordSubmit = () => {
    const {
      requestActivateFactFind,
      oktaAuth,
      recoveryToken,
      clientId,
      loanApplicationId,
      trackFactFindSignupSuccess,
    } = this.props;
    requestActivateFactFind(oktaAuth);
    trackFactFindSignupSuccess({ recoveryToken, clientId, loanApplicationId });
  };

  render() {
    const {
      isSpinnerLoading,
      recoveryToken,
      fullPageSpinnerLoading,
    } = this.props;
    const { showPasswordScreen } = this.state;

    if (showPasswordScreen) {
      return (
        <MyCRMLogin
          headingTitle='Choose a password to secure your profile'
          handleSubmit={this.handlePasswordSubmit}
          centeredTitle
          showFooter
          recoveryToken={recoveryToken}
          fullPageSpinnerLoading={fullPageSpinnerLoading}
        />
      );
    }

    return (
      <View theme='sharedLogin' showLogin={false}>
        <Spinner loading={isSpinnerLoading}>
          <FullBgContentsWrapper />
        </Spinner>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSpinnerLoading: UISelectors.hasActiveSpinners(state),
    fullPageSpinnerLoading: state.UISettings.fullPageSpinnerLoading,
    recoveryTokenStatus: scenarioSelectors.recoveryTokenStatus(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSharedClientId: scenarioActions.setSharedClientId,
      setSharedLoanAppId: scenarioActions.setSharedLoanAppId,
      setHandholdSections: UIActions.setHandholdSections,
      requestLoginTokenShare: scenarioActions.requestLoginTokenShare,
      requestVerifyRecoveryToken: scenarioActions.requestVerifyRecoveryToken,
      setPartialProfileSections: UIActions.setPartialProfileSections,
      goToPathWithAnimation: UIActions.goToPathWithAnimation,
      requestActivateFactFind: scenarioActions.requestActivateFactFind,
      trackFactFindSignupAttempt: scenarioActions.trackFactFindSignupAttempt,
      trackFactFindSignupSuccess: scenarioActions.trackFactFindSignupSuccess,
      setRecoveryTokenStatus: scenarioActions.setRecoveryTokenStatus,
    },
    dispatch,
  );

export default withOktaAuth(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    sanityCheck,
  )(SharedLogin),
);
