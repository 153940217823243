import React from 'react';
import PropTypes from 'prop-types';
import InformationIcon from 'assets/icons/information-pig.png';
import MailContinue from 'assets/icons/mail-continue.png';
import TimeIcon from 'assets/icons/time-icon.png';
import Button from 'components/Button/Button';
import { DataCollectionPageSection } from 'components/DataCollectionPageSection/DataCollectionPageSection';
import DataCollectionPageSectionStyle from '../DataCollectionPageSection/DataCollectionPageSection.css';
import styles from './DataCollectionLandingContent.css';

const GetStarted = ({ name, onLogoutClick, setGetStarted }) => {
  return (
    <DataCollectionPageSection id='dataCollectionLanding'>
      <h1 className={styles.name}>
        Hi {name}, <br className={DataCollectionPageSectionStyle.isMobile} />{' '}
        let&apos;s get started
      </h1>
      <ul className={styles.nextStepContainer}>
        <li>
          <div className={styles.iconContainer}>
            <img src={InformationIcon} />
            <h3 className={DataCollectionPageSectionStyle.isMobile}>
              Your information
            </h3>
          </div>

          <div>
            <h3 className={DataCollectionPageSectionStyle.isDesktop}>
              Your information
            </h3>
            <p className={styles.lighterText}>
              To help you find & apply for the right loan, you may need to
              provide a range of information about your assets, liabilities,
              employment and more
            </p>
          </div>
        </li>

        <li>
          <div className={styles.iconContainer}>
            <img src={TimeIcon} />
            <h3 className={DataCollectionPageSectionStyle.isMobile}>
              45 minutes
            </h3>
          </div>
          <div>
            <h3 className={DataCollectionPageSectionStyle.isDesktop}>
              45 minutes
            </h3>
            <p className={styles.lighterText}>
              It takes around 45 minutes to provide your information & complete
              your profile
            </p>
          </div>
        </li>

        <li>
          <div className={styles.iconContainer}>
            <img src={MailContinue} />
            <h3 className={DataCollectionPageSectionStyle.isMobile}>
              Save & continue
            </h3>
          </div>
          <div>
            <h3 className={DataCollectionPageSectionStyle.isDesktop}>
              Save & continue
            </h3>
            <p className={styles.lighterText}>
              Pick up where you left off at any time via the link in your email
            </p>
          </div>
        </li>
      </ul>

      <div className={styles.buttonGroup}>
        <Button theme='linkButtonTheme' onClick={onLogoutClick}>
          I&apos;m not ready, log out
        </Button>

        <Button
          onClick={() => setGetStarted(true)}
          theme='buttonNext'
          className={styles.blockBtn}
        >
          Let&apos;s get started
        </Button>
      </div>
    </DataCollectionPageSection>
  );
};

GetStarted.propTypes = {
  name: PropTypes.string,
  onLogoutClick: PropTypes.func,
  setGetStarted: PropTypes.func,
};

export default GetStarted;
