/* eslint-disable sonarjs/cognitive-complexity */
import ClockIcon from 'assets/icons/clock-new.svg';
import EnergyIcon from 'assets/icons/energy.svg';
import HeartIcon from 'assets/icons/heart.svg';
import LockIcon from 'assets/icons/lock-new.svg';
import Button from 'components/Button/Button';
import { DataCollectionPageSection } from 'components/DataCollectionPageSection/DataCollectionPageSection';
import DataCollectionStepper from 'components/DataCollectionStepper/DataCollectionStepper';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import SVGInline from 'react-svg-inline';
import BulbIcon from 'assets/icons/bulb.svg';
import { DATA_COLLECTION_STATUS_POLLING_PATH } from 'shared/constants/paths';
import styles from './DataCollectionBankStatementFrame.css';
import IframeResizer from 'iframe-resizer-react';
import classNames from 'classnames';
import { postDataCollectionProcessEvent } from 'services/dataCollectionApi';
import {
  DATA_COLLECTION_EVENT,
  DATA_COLLECTION_PROCESS,
} from 'constants/dataCollection';
import { useModal } from '../../contexts/ModalContext';
import { useHistory } from 'react-router-dom';
import { DataCollectionSupplyManuallyModal } from './DataCollectionSupplyManuallyModal';

const bankStatementsUrl = window.LM_CONFIG.BANK_STATEMENTS_IFRAME_URI || '';

const DataCollectionBankStatementFrame = ({
  applicationId,
  familyContactId,
  clientId,
  setPageError,
  selectedProcesses,
}) => {
  const [showIFrame, setShowIFrame] = useState(false);
  const [finished, setFinished] = useState(false);
  const history = useHistory();
  const modal = useModal();

  useEffect(() => {
    if (!bankStatementsUrl) {
      return;
    }

    const bankStatementsOrigin = new URL(bankStatementsUrl).origin;
    window.addEventListener('message', async (event) => {
      if (event.origin === bankStatementsOrigin) {
        const data = JSON.parse(event.data);
        if (data.event === 'submit_all') {
          try {
            await postDataCollectionProcessEvent(
              applicationId,
              DATA_COLLECTION_PROCESS.Illion,
              DATA_COLLECTION_EVENT.Begin,
            );
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
          }
          setFinished(true);
        }
      }
    });
  }, [applicationId]);

  const renderIFrame = () => {
    const URL = bankStatementsUrl
      .replace('{LoanScenarioId}', applicationId)
      .replace('{FamilyContactId}', familyContactId)
      .replace('{ClientId}', clientId);

    return (
      <IframeResizer
        log
        src={URL}
        style={{ width: '1px', minWidth: '100%', border: 'none' }}
      />
    );
  };

  const renderSecurityPoints = () => (
    <ul>
      <li>
        <SVGInline svg={LockIcon} width='20px' height='20px' />
        <div>We never store your login details</div>
      </li>
      <li>
        <SVGInline svg={EnergyIcon} width='20px' height='20px' />
        <div>Bank level encryption keeps your data safe</div>
      </li>
      <li>
        <SVGInline svg={ClockIcon} width='20px' height='20px' />
        <div>A one-time view of your bank statements</div>
      </li>
      <li>
        <SVGInline svg={HeartIcon} width='20px' height='20px' />
        <div>Trusted by millions across Australia</div>
      </li>
    </ul>
  );

  const renderBankCard = () => (
    <div className={classNames(styles.bankCard)}>
      {showIFrame ? (
        renderIFrame()
      ) : (
        <>
          <h2>Connect to your banks</h2>
          <p>
            Log in to your banks and select the accounts required to share 180
            days of bank statements in a one-time view
          </p>
          <Button
            theme='buttonNext'
            className={styles.bankBtn}
            icon='sl-custom-dollar-currency-3'
            onClick={() => setShowIFrame(true)}
          >
            Connect to banks
          </Button>
        </>
      )}
    </div>
  );

  const supplyManuallyClicked = () => {
    modal.showModal({
      title: 'Supply manually? This will take longer.',
      content: (
        <DataCollectionSupplyManuallyModal
          cancel={() => modal.hideModal()}
          confirm={async () => {
            try {
              await postDataCollectionProcessEvent(
                applicationId,
                DATA_COLLECTION_PROCESS.Illion,
                DATA_COLLECTION_EVENT.Skip,
              );
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error(error);
              setPageError({ status: 500 });
              return;
            }
            history.push(DATA_COLLECTION_STATUS_POLLING_PATH);
          }}
        />
      ),
    });
  };

  const stepIndex = selectedProcesses.includes(DATA_COLLECTION_PROCESS.Scantek)
    ? 1
    : 0;

  return (
    <DataCollectionPageSection id='dataCollectionBankStatement'>
      <DataCollectionStepper
        activeStep={stepIndex}
        selectedProcesses={selectedProcesses}
      />
      <h1 className={classNames(styles.title)}>
        Provide your expenses, instantly
      </h1>
      <div>
        <p>
          No need to play the guessing game when it comes to your everyday
          expenses.
        </p>
        <p>
          Connect securely to your bank accounts and send across your statements
          in seconds not hours.
        </p>
      </div>

      <div className={classNames(styles.securityPoints)}>
        <h2>Safe and sound</h2>
        {renderSecurityPoints()}
      </div>
      {renderBankCard()}
      <div className={styles.alert}>
        <SVGInline svg={BulbIcon} />
        <p>
          Remember to connect to <strong>every bank</strong> you have accounts,
          credit cards, or loans with. This is important as it provides a more
          accurate view of expenses on your application.
        </p>
      </div>
      <div className={styles.buttonGroup}>
        <Button
          theme='linkButtonTheme'
          onClick={supplyManuallyClicked}
          disabled={finished}
        >
          Supply manually
        </Button>
        <Button
          url={DATA_COLLECTION_STATUS_POLLING_PATH}
          theme='buttonNext'
          className={styles.blockBtn}
          disabled={!finished}
        >
          Next step
        </Button>
      </div>
      <p className={styles.footer}>
        This service in partnership with our partners at illion Open Data
        solutions.{' '}
        <a
          href='https://www.bankstatements.com.au/about/security'
          target='_blank'
          rel='noopener noreferrer'
        >
          Learn more
        </a>
        .
      </p>
    </DataCollectionPageSection>
  );
};

DataCollectionBankStatementFrame.propTypes = {
  applicationId: PropTypes.number,
  familyContactId: PropTypes.number,
  clientId: PropTypes.number,
  setPageError: PropTypes.func,
  selectedProcesses: PropTypes.arrayOf(PropTypes.string),
};

export default DataCollectionBankStatementFrame;
