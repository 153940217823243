import IDBadgeIcon from 'assets/icons/id_badge.png';
import MedicalInformationIcon from 'assets/icons/medical_information.png';
import PassportIcon from 'assets/icons/passport.png';
import PhoneIcon from 'assets/icons/phone_iphone.png';

import Button from 'components/Button/Button';
import { DataCollectionPageSection } from 'components/DataCollectionPageSection/DataCollectionPageSection';
import DataCollectionStepper from 'components/DataCollectionStepper/DataCollectionStepper';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  DATA_COLLECTION_BANK_STATEMENT_PATH,
  DATA_COLLECTION_ID_MANUAL_VERIFICATION_PATH,
  DATA_COLLECTION_STATUS_POLLING_PATH,
} from 'shared/constants/paths';
import styles from './DataCollectionVerifyId.css';
import {
  postIdentityVerifications,
  postDataCollectionProcessEvent,
} from 'services/dataCollectionApi';
import {
  DATA_COLLECTION_EVENT,
  DATA_COLLECTION_PROCESS,
} from 'constants/dataCollection';

const VerificationStage = {
  INIT: 'INIT',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR',
};

const CompletedMessage = () => {
  return (
    <div className={styles.completedContainer}>
      <p>Identity verification complete.</p>
      <p>Thank you!</p>
    </div>
  );
};

const DataCollectionVerifyId = ({
  pushSpinner,
  popSpinner,
  setPageError,
  applicationId,
  selectedProcesses,
}) => {
  const [idVerificationStage, setIdVerificationStage] = useState(
    VerificationStage.INIT,
  );

  const nextPagePath = selectedProcesses.includes(
    DATA_COLLECTION_PROCESS.Illion,
  )
    ? DATA_COLLECTION_BANK_STATEMENT_PATH
    : DATA_COLLECTION_STATUS_POLLING_PATH;

  useEffect(() => {
    pushSpinner('LOADING');
    const script = document.createElement('script');
    script.src = 'https://sctk.au/client/scantek.js';
    script.addEventListener('load', () => {
      popSpinner('LOADING');
    });
    script.addEventListener('error', (event) => {
      // eslint-disable-next-line no-console
      console.error('scantek event error:', event);
      popSpinner('LOADING');
      setPageError({ status: 500 });
    });
    document.body.append(script);
  }, [popSpinner, pushSpinner, setPageError]);

  const handleStartIdVerification = async () => {
    pushSpinner('LOADING');
    setIdVerificationStage(VerificationStage.IN_PROGRESS);

    let sharedCode = '';
    try {
      const response = await postIdentityVerifications(applicationId);
      sharedCode = response.sharedCode;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      popSpinner('LOADING');
      setPageError({ status: 500 });
      return;
    }

    window.scantek.events.addEventListener('ready', () => {
      popSpinner('LOADING');
      const config = {
        domElement: '#scantekContainer',
        launch: 'immediate',
        display: 'inline',
      };
      window.scantek.setupVerification(config);
    });

    window.scantek.events.addEventListener('status', (e) => {
      console.info('Scantek status', e.status);
    });

    window.addEventListener('message', (e) => {
      if (e.origin && e.origin.includes('sctk')) {
        console.info('Scantek message', e.data);
      }
    });

    window.scantek.events.addEventListener('complete', async () => {
      setIdVerificationStage(VerificationStage.COMPLETED);
      await postDataCollectionProcessEvent(
        applicationId,
        DATA_COLLECTION_PROCESS.ManuelIdVerify,
        DATA_COLLECTION_EVENT.Skip,
      );
    });

    window.scantek.events.addEventListener('error', () => {
      popSpinner('LOADING');
      setIdVerificationStage(VerificationStage.ERROR);
    });

    window.scantek.init(sharedCode);
  };

  // eslint-disable-next-line unicorn/better-regex
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

  return (
    <DataCollectionPageSection
      id='dataCollectionIDVerification'
      fullWidth={idVerificationStage === VerificationStage.IN_PROGRESS}
    >
      <DataCollectionStepper
        activeStep={0}
        selectedProcesses={selectedProcesses}
      />

      {idVerificationStage === VerificationStage.IN_PROGRESS && (
        <div id='scantekContainer' className={styles.scantekContainer} />
      )}

      {(idVerificationStage === VerificationStage.INIT ||
        idVerificationStage === VerificationStage.COMPLETED) && (
        <>
          <h1 className={styles.verifyTitle}>Verify your identity</h1>
          <p className={styles.lighterText}>
            Providing your ID documents is required by lenders, to make sure
            that it&apos;s really you and to protect you against identity fraud
          </p>
          <div className={styles.card}>
            {idVerificationStage === VerificationStage.COMPLETED ? (
              <CompletedMessage />
            ) : (
              <>
                <h2>Have these handy</h2>
                <div className={styles.introContainer}>
                  <div>
                    <div className={styles.introItem}>
                      <img src={IDBadgeIcon} />
                      <p>An Australian driver licence</p>
                    </div>

                    <div className={styles.introItem}>
                      <img src={MedicalInformationIcon} />
                      <p>A Medicare card</p>
                    </div>

                    <div className={styles.introItem}>
                      <img src={PassportIcon} />
                      <p>Or a passport</p>
                    </div>

                    {!isMobile && (
                      <div className={styles.introItem}>
                        <img src={PhoneIcon} />
                        <p>A mobile phone or tablet</p>
                      </div>
                    )}
                  </div>
                </div>
                <Button
                  theme='buttonNext'
                  className={styles.startIdBtn}
                  onClick={handleStartIdVerification}
                >
                  Start identity verification
                </Button>
              </>
            )}
          </div>
          <div className={styles.buttonGroup}>
            <Button
              url={nextPagePath}
              theme='buttonNext'
              className={styles.blockBtn}
              disabled={idVerificationStage !== VerificationStage.COMPLETED}
            >
              Next
            </Button>
          </div>
        </>
      )}

      {idVerificationStage === VerificationStage.ERROR && (
        <>
          <h1 className={styles.verifyTitle}>Verify your identity</h1>
          <div className={styles.introContainer}>
            <p>
              An unexpected error occurred while attempting setup identity
              verification.
            </p>
          </div>
          <div className={styles.buttonGroup}>
            <Button
              url={DATA_COLLECTION_ID_MANUAL_VERIFICATION_PATH}
              theme='buttonNext'
              className={styles.blockBtn}
            >
              Supply manually
            </Button>
          </div>
        </>
      )}
    </DataCollectionPageSection>
  );
};

DataCollectionVerifyId.propTypes = {
  pushSpinner: PropTypes.func,
  popSpinner: PropTypes.func,
  applicationId: PropTypes.number,
  setPageError: PropTypes.func,
  selectedProcesses: PropTypes.arrayOf(PropTypes.string),
};

export default DataCollectionVerifyId;
