import AnimatedChecklist from 'components/AnimatedChecklist/AnimatedChecklist';
import { DataCollectionPageSection } from 'components/DataCollectionPageSection/DataCollectionPageSection';
import DataCollectionStepper from 'components/DataCollectionStepper/DataCollectionStepper';
import React, { useState, useEffect, useRef } from 'react';
import styles from './DataCollectionStatusPollingContent.css';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const LinearProgressBar = (props) => {
  return (
    <div className={styles.mainProgressBarDiv}>
      <div className={styles.emptyProgressBar}>
        <div
          className={classNames(styles.fillingProgressBar, {
            [styles.blinkBar]: props.isInProgress,
          })}
          style={{
            left: props.percent - 100 + '%',
            transition: '1s',
          }}
        />
      </div>
    </div>
  );
};

export const DataCollectionStatusPollingContent = ({
  isSuccess,
  isInProgress,
  showCreditCheckRetryContent,
  selectedProcesses,
}) => {
  const [percent, setPercent] = useState(1);
  const intervalRef = useRef();
  const hasFailed = !isInProgress && !isSuccess;

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setPercent((p) => p + 1);
    }, 500);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (percent >= 97 || hasFailed) {
      clearInterval(intervalRef.current);
    }
  }, [percent, hasFailed]);

  useEffect(() => {
    if (isSuccess) {
      setPercent(100);
    }
  }, [isSuccess]);

  return (
    <DataCollectionPageSection id='dataCollectionStatusPolling' inverse>
      <DataCollectionStepper
        activeStep={selectedProcesses.length}
        inverse
        selectedProcesses={selectedProcesses}
      />
      {showCreditCheckRetryContent ? (
        <div className={styles.retryContentBlock}>
          <h1>
            Preparing your Fact Find now, sit tight!
            <br />
            Please don&apos;t refresh.
            <br />
            This might take a few minutes.
          </h1>
        </div>
      ) : (
        <div className={styles.contentBlock}>
          <h1>
            We&apos;re processing your information, please don&apos;t refresh or
            go back
          </h1>
          <AnimatedChecklist
            items={[
              'Collecting and analysing your data',
              'Pre-filling your financial information',
              'Directing you to Online Fact Find',
            ]}
            className={styles.checkList}
          />
        </div>
      )}
      <LinearProgressBar percent={percent} isInProgress={isInProgress} />
    </DataCollectionPageSection>
  );
};

DataCollectionStatusPollingContent.propTypes = {
  isSuccess: PropTypes.bool,
  isInProgress: PropTypes.bool,
  showCreditCheckRetryContent: PropTypes.bool,
  selectedProcesses: PropTypes.arrayOf(PropTypes.string),
};

DataCollectionStatusPollingContent.defaultProps = {
  showCreditCheckRetryContent: false,
};
