import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from 'components/Button/Button';
import AutocompleteAddress from 'components/AutocompleteInput/AutocompleteAddress';
import Question from 'components/Question/Question';

import * as UISelectors from 'selectors/UISelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as dataCollectionSelectors from 'selectors/dataCollectionSelectors';

import loanApplicationActions from 'actions/loanApplicationActions';
import dataCollectionActions from 'actions/dataCollectionActions';

import UIActions from 'actions/UIActions';
import Spinner from 'components/Spinner/Spinner';
import View from 'components/View/View';
import { injectIntl } from 'react-intl';
import styles from './DataCollectionCreditCheckRetry.css';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import { postCreditCheckRetry } from 'services/dataCollectionApi';
import { DataCollectionStatusPollingContent } from 'components/DataCollectionStatusPollingContent/DataCollectionStatusPollingContent';

const DataCollectionManualCreditRetry = ({
  isSpinnerLoading,
  pushSpinner,
  popSpinner,
  setPageError,
  pollDataCollectionRetryStatusAndEvents,
  updateLoanApplication,
  applicationId,
  clientId,
  familyId,
  isDataCollectionSuccess,
  isDataCollectionPending,
  selectedProcesses,
}) => {
  const [address, setAddress] = useState();
  const [submitClicked, setSubmitClicked] = useState(false);
  const [isPollingStatus, setIsPollingStatus] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNextClick = async () => {
    setSubmitClicked(true);
    if (!address?.formattedAddress && !address?.text) {
      return;
    }

    try {
      pushSpinner('LOADING');
      await postCreditCheckRetry(
        applicationId,
        address?.formattedAddress ?? address?.text,
      );
      updateLoanApplication({
        currentFamilyId: familyId,
        currentClientId: clientId,
        hasCreditCheckRetried: true,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      popSpinner('LOADING');
      setPageError({ status: 500 });
      return;
    }

    pollDataCollectionRetryStatusAndEvents({ applicationId, clientId });
    popSpinner('LOADING');
    setIsPollingStatus(true);
  };

  if (isPollingStatus) {
    return (
      <View inverse showMenu={false} showFooter={false}>
        <Spinner loading={isSpinnerLoading}>
          <DataCollectionStatusPollingContent
            isInProgress={isDataCollectionPending}
            isSuccess={isDataCollectionSuccess}
            showCreditCheckRetryContent
            selectedProcesses={selectedProcesses}
          />
        </Spinner>
      </View>
    );
  }

  return (
    <View showMenu={false} showHelpFooter>
      <Spinner loading={isSpinnerLoading}>
        <ContentsWrapper
          id='dataCollectionCreditCheck'
          className={styles.creditCheckPage}
        >
          <div className={styles.card}>
            <div className={styles.heading}>
              <h1>One more step to verify your identity</h1>
              <p>Please confirm your address</p>
            </div>

            <form className={styles.form}>
              <Question
                id='currentAddress'
                key='question.currentAddress'
                label='Current residential address'
                className='fullWidthInput'
                error='Please enter a valid address'
                showError={
                  submitClicked && !address?.formattedAddress && !address?.text
                }
              >
                <div>
                  <AutocompleteAddress
                    setError={() => {}}
                    action={setAddress}
                    intlOnChange={setAddress}
                    addressId='currentAddress'
                    value={address?.formattedAddress ?? address?.text}
                    intlValue={address}
                    forceFocus={
                      submitClicked &&
                      !address?.formattedAddress &&
                      !address?.text
                    }
                  />
                </div>
              </Question>
            </form>
          </div>
          <div className={styles.buttonGroup}>
            <Button
              theme='buttonNext'
              className={styles.blockBtn}
              onClick={handleNextClick}
            >
              Next
            </Button>
          </div>
        </ContentsWrapper>
      </Spinner>
    </View>
  );
};

DataCollectionManualCreditRetry.propTypes = {
  isSpinnerLoading: PropTypes.bool,
  pushSpinner: PropTypes.func,
  popSpinner: PropTypes.func,
  applicationId: PropTypes.number,
  setPageError: PropTypes.func,
  updateLoanApplication: PropTypes.func,
  pollDataCollectionRetryStatusAndEvents: PropTypes.func,
  clientId: PropTypes.number,
  familyId: PropTypes.number,
  isDataCollectionSuccess: PropTypes.bool,
  isDataCollectionPending: PropTypes.bool,
  selectedProcesses: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state) => ({
  isSpinnerLoading: UISelectors.hasActiveSpinners(state),
  applicationId: applicationSelectors.getApplicationId(state),
  clientId: clientSelectors.getPrimaryApplicantId(state),
  familyId: clientSelectors.primaryApplicantContactId(state),
  isDataCollectionSuccess: dataCollectionSelectors.isDataCollectionSuccess(
    state,
  ),
  isDataCollectionPending: dataCollectionSelectors.isDataCollectionPending(
    state,
  ),
  selectedProcesses: dataCollectionSelectors.selectedProcesses(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushSpinner: UIActions.pushSpinner,
      popSpinner: UIActions.popSpinner,
      setPageError: UIActions.setPageError,
      pollDataCollectionRetryStatusAndEvents:
        dataCollectionActions.pollDataCollectionRetryStatusAndEvents,
      updateLoanApplication: loanApplicationActions.updateLoanApplication,
    },
    dispatch,
  );

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DataCollectionManualCreditRetry),
);
